<template>
  <section class="mev-chat-section">
    <div class="chat-side-bar">
      <div class="top-bar">
        <b-avatar
          v-if="appBarData.avatar !== null"
          :src="getImage(appBarData.avatar)"
        />
        <b-avatar
          v-else
          :text="`${appBarData.first_name.substr(0,1)}${appBarData.last_name.substr(0,1)}`"
          variant="info"
        />

        <!-- basic search -->
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Search" />
        </b-input-group>
      </div>
      <div class="contact-view">
        <div
          v-if="chats.length > 0"
          class="contact-heading"
        >
          <h4 class="text-primary">
            Chats
          </h4>
        </div>
        <div class="contact-list-box">
          <div
            v-for="item in chats"
            :key="item.id"
            class="contact-list"
            :class="active_contact === item.id ? 'active': '' "
            @click="getMessage(item)"
          >
            <b-avatar
              v-if="item.avatar !== null"
              :src="getImage(item.avatar)"
            />
            <b-avatar
              v-else
              :text="`${item.first_name.substr(0,1)}${item.last_name.substr(0,1)}`"
              variant="success"
            />
            <div class="contact-content">
              <div class="name-date-view">
                <h5>{{ item.first_name }} {{ item.last_name }}</h5>
                <small>{{ date(item.created_at) }}</small>
              </div>
              <span>{{ item.message }}</span>
            </div>
          </div>
        </div>

        <div class="contact-heading">
          <h4 class="text-primary">
            Contacts
          </h4>
        </div>

        <div class="contact-list-box">
          <div
            v-for="item in contacts"
            :key="item.id"
            class="contact-list"
            :class="active_contact === item.id ? 'active': '' "
            @click="startChat(item)"
          >
            <b-avatar
              v-if="item.avatar !== null"
              :src="getImage(item.avatar)"
            />
            <b-avatar
              v-else
              :text="`${item.first_name.substr(0,1)}${item.last_name.substr(0,1)}`"
              variant="success"
            />
            <div class="contact-content">
              <div class="name-date-view">
                <h5>{{ item.first_name }} {{ item.last_name }}</h5>
                <span><b-badge variant="info">
                  {{ item.user_type }}
                </b-badge></span>
              </div>
              <span>{{ item.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-body-content">
      <div
        v-if="single_chats.length > 0 || enableChat"
        class="top-bar"
      >
        <div class="top-bar-content">
          <b-avatar
            v-if="contact.avatar !== null"
            :src="getImage(contact.avatar)"
          />
          <b-avatar
            v-else
            :text="`${contact.first_name.substr(0,1)}${contact.last_name.substr(0,1)}`"
            variant="success"
          />
          <span>{{ contact.first_name }} {{ contact.last_name }}</span>
        </div>

        <feather-icon
          class="mr-1"
          icon="SearchIcon"
        />
      </div>
      <div class="body-content">
        <div
          v-if="enableChat == false"
          class="chat_emptyp_data"
        >
          <div class="icon-view">  <feather-icon
            size="50"
            icon="MessageCircleIcon"
          /></div>
          <div class="text-view">
            Start Conversation
          </div>
        </div>
        <div
          v-for="item in single_chats"
          :key="item.id"
          class="chat-content-box"
        >
          <div
            v-if="item.from !== appBarData.id"
            class="client-chat-box"
          >
            <b-avatar
              v-if="contact.avatar !== null"
              :src="getImage(contact.avatar)"
            />
            <b-avatar
              v-else
              :text="`${contact.first_name.substr(0,1)}${contact.last_name.substr(0,1)}`"
              variant="success"
            />
            <div class="message-box">
              {{ item.message }}
            </div>
          </div>

          <div
            v-if="item.from === appBarData.id"
            class="user-chat-box"
          >

            <div class="message-box">
              {{ item.message }}
            </div>
            <b-avatar
              v-if="appBarData.avatar !== null"
              :src="getImage(appBarData.avatar)"
            />
            <b-avatar
              v-else
              :text="`${appBarData.first_name.substr(0,1)}${appBarData.last_name.substr(0,1)}`"
              variant="info"
            />
          </div>
        </div>
      </div>
      <div
        v-if="single_chats.length > 0 || enableChat"
        class="form-box"
      >
        <b-form-input
          id="basicInput"
          v-model="message"
          placeholder="Enter message here..."
        />
        <div class="button-view">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="sendMessage"
          >
            Send Message
          </b-button>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import {
  BAvatar, BInputGroup, BBadge, BFormInput, BInputGroupPrepend, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import { echo } from '../../../libs/socket'
import chatSound from '../../../assets/audio/chat.mp3'

export default {
  components: {
    BAvatar,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BBadge,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      active_contact: '',
      user: {},
      message: '',
      to: null,
      from: null,
      contact: {},
      chatSound,
      enableChat: false,
    }
  },
  computed: {
    chats() {
      return this.$store.state.chat.chats
    },
    contacts() {
      return this.$store.state.chat.contacts
    },
    single_chats: {
      get() {
        return this.$store.state.chat.single_chats
      },
      set(value) {
        return value
      },
    },
    appBarData() {
      return this.$store.getters['auth/appBarData']
    },
  },
  mounted() {
    this.fetchChats()
    this.clearChat()
    // eslint-disable-next-line global-require
  },
  methods: {
    date(data) {
      return moment(data).format('Do, MMM YYYY, h:mm a')
    },
    fetchChats() {
      const mortgage_id = this.$store.getters["auth/mortgageId"]
      this.$store.dispatch('chat/fetchChatsAndContacts',{mortgage_id,query:''})
      // this.$store.dispatch('chat/fetchChatsAndContacts')
    },
    clearChat() {
      this.$store.dispatch('chat/clearSingleChat')
    },
    getMessage(item) {
      this.enableChat = true
      const data = item
      this.contact = data
      this.active_contact = data.id
      echo.private(`chats.${this.to}`).stopListening('NewChatMessage')
      if (this.appBarData.id === item.to) {
        this.to = data.from
      } else {
        this.to = data.to
      }
      data.user_id = this.appBarData.id
      this.clearChat()
      this.$store.dispatch('chat/getSingleChat', data)
      echo.private(`chats.${this.to}`)
        .listen('NewChatMessage', message => {
          if (message.chat.from === this.to) {
            const chatItems = this.single_chats
            this.single_chats = chatItems.push(message.chat)
            const audio = new Audio(this.chatSound)
            audio.play()
            this.fetchChats()
          }
        })
    },
    startChat(item) {
      this.enableChat = true
      this.contact = item
      const data = item
      this.contact = data
      this.active_contact = data.id
      this.to = data.id
      this.from = this.appBarData.id
      data.user_id = this.appBarData.id
      data.from = this.appBarData.id
      data.to = item.id
      this.$store.dispatch('chat/getSingleChat', data)
      echo.private(`chats.${this.to}`)
        .listen('NewChatMessage', message => {
          if (message.chat.from === this.to) {
            const chatItems = this.single_chats
            this.single_chats = chatItems.push(message.chat)
            const audio = new Audio(this.chatSound)
            audio.play()
            this.fetchChats()
          }
        })
    },
    sendMessage() {
      if (this.message === '') return
      this.$store.dispatch('chat/sendMessage', { message: this.message, to: this.to })
      const audio = new Audio(this.chatSound)
      audio.play()
      const newMessage = {
        from: this.appBarData.id, message: this.message, to: this.to,
      }
      const chatItems = this.single_chats
      this.single_chats = chatItems.push(newMessage)
      this.message = ''
      this.fetchChats()
    },
    getImage(image) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
  },
}
</script>

<style>

</style>
